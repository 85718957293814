const ToggleWidget = ({ topic, createdAt, data, onSend }: any) => {

    const handleClick = () => {
        const command = `${topic}/p1`;
        console.log('!!!click');
        if (onSend) {
            console.log('>>> OnOffWidget onClick cmmand: ', command);
            onSend({ topic: command, data: '{}'})
        }
    } 

    return (
        <div className="container">
            <div className="row">
                <div className="col">{topic}</div>
                <div className="col">{createdAt}</div>
                <div className="col">{data}</div>
                <div className="col">
                    <button type="button" className="btn btn-secondary" onClick={handleClick}>Toggle</button>
                </div>
            </div>
        </div>
        // Simple Widget
    );
}

export default ToggleWidget;
const SimpleWidget = ({ topic, createdAt, data }: any) => {

    return (
        <div className="container">
            <div className="row">
                <div className="col">{topic}</div>
                <div className="col">{createdAt}</div>
                <div className="col">{data}</div>
                <div className="col"></div>
            </div>
        </div>
        // Simple Widget
    );
}

export default SimpleWidget;
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import { useAppSelector } from './app/hooks';
import { RootState } from './app/store';
const AppRouter = () => {
  const logged = useAppSelector((state: RootState) => state.auth.logged)
  console.log('>>> AppRouter logged: ', logged)
  // const loader: LoaderFunction = () => {
  //   console.log('>>> loader..');
  //   return redirect('/login');
  // }
  // const logged = false;
return (         
  <Routes>
    <Route path='/' element={ logged ? <Dashboard/> : <Navigate to="/login"/>} />
    <Route path='/login' element={<Login/>} />

    {/* <Route path='/'>
      { logged ? <Redirect to="" />}
    </Route> */}
  </Routes>
)}
export default AppRouter;
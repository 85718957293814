import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { getDataAsync, sendDataAsync } from "../slices/DashboardSlice";
import SimpleWidget from "./widtets/SimpleWidget";
import OnOffWidget from "./widtets/OnOffWidget";
import ToggleWidget from "./widtets/ToggleWidget";
import { SendDataParams } from "../services/DataService";

const Dashboard = () => {

    const sensors = useAppSelector((state: RootState) => state.dash.sensors);
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getDataAsync());
    }, [])

    const onSend = (data: SendDataParams) => {
        dispatch(sendDataAsync(data));
    }

    return (
        <>
            <h2>Dashboard</h2>
            {sensors.map((s, i) => (
                <div key={i}>
                    {/* <div key={i}><span>{s.topic}</span> <span>{s.data}</span></div> */}
                    {s.topic === 'nam/home/aqua/t' &&
                        <SimpleWidget topic={s.topic} createdAt={s.createdAt} data={s.data} onSend={onSend}></SimpleWidget>}
                    {s.topic === 'nam/home/aqua/light' &&
                        <OnOffWidget topic={s.topic} createdAt={s.createdAt} data={s.data} onSend={onSend}></OnOffWidget>}
                    {s.topic === 'nam/home/aqua/feeder' &&
                        <ToggleWidget topic={s.topic} createdAt={s.createdAt} data={s.data} onSend={onSend}></ToggleWidget>}
                </div>
            ))}
        </>);
}
export default Dashboard;


/**
 * 
     { topic: 'nam/home/aqua/t', data: ''},
    { topic: 'nam/home/aqua/light', data: ''},
    { topic: 'nam/home/aqua/feeder', data: ''}
* 
 */
import { useForm } from "react-hook-form";
import AuthService from "../services/AuthService";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { loginAsync } from "../slices/AuthSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Login.css';


// import AuthService from "../services/AuthService";

type FormValues = {
    login: string;
    password: string;
};

const Login = () => {

    const error = useAppSelector((state: RootState) => state.auth.error)
    const logged = useAppSelector((state: RootState) => state.auth.logged)
    const dispatch = useAppDispatch()
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();
    const onSubmit = handleSubmit((data) => {
        dispatch(loginAsync(data));
    });
    const navigate = useNavigate();
    useEffect(() => {
        if (logged) {
            navigate('/');
        }
    }, [logged]);

    return (
        <>
        
            {/* <div className="p-3 m-0 border-0 bd-example m-0 border-0"> */}
            <div className="p-3 m-0 border-0 bd-example m-0 border-0">
            <div className="container login">
                <form onSubmit={onSubmit}>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Login</label>
                    <input { ...register('login')} type="text" className="form-control" id="exampleFormControlInput1" placeholder="login" />
                </div>
                <div className="mb-3">
                    <label htmlFor="inputPassword5" className="form-label">Password</label>
                    <input { ...register('password')} type="password" id="inputPassword5" className="form-control" aria-labelledby="passwordHelpBlock" />
                    {/* <div id="passwordHelpBlock" className="form-text">
                        Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.
                    </div> */}
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary" type="submit">Login</button>
                </div>
                </form>
                { error && <div>Error: { error.message } Code: { error.code }</div>}
            </div>
            </div>
        </>)
}
export default Login;
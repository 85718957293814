import HttpService from './HttpService'

export interface AuthPayload {
    login: string;
    password: string;
}

class AuthService extends HttpService {
  login(data: AuthPayload) {
    return this.http
      .post<any>('/auth/login', data)
      .then(this.onResponse)
    //   .then(data => {
    //     console.log('login data', data)
    //     localStorage.setItem('token', data.access_token)
    //     return data;
    //   })
      .catch(this.onError)
  }
}

export default new AuthService()

import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { sendDataAsync } from "../../slices/DashboardSlice";

const OnOffWidget = ({ topic, createdAt, data, onSend }: any) => {
    const [on, setOn] = useState(true);
    // const dispatch = useAppDispatch();
    useEffect(() => {
        // parce data
        try {
            const dataObj = JSON.parse(data);
            setOn(dataObj[0] === 1);
        } catch {
        }
    }, [data]);
    // const onClick = (suffix: string) => () => {
    //     const command = `${topic}/${suffix}`;
    //     if (onSend) {
    //         console.log('>>> OnOffWidget onClick cmmand: ', command);
    //         onSend({ topic: command, data: '{}'})
    //     }
    //     // dispatch(sendDataAsync({ topic: command, data: '{}'}));
    //     return undefined;
    // }

    const handleClick = () => {
        console.log('!!!click');
        const command = `${topic}/${on ? 'off' : 'on'}`;
        if (onSend) {
            console.log('>>> OnOffWidget onClick cmmand: ', command);
            onSend({ topic: command, data: '{}'})
        }
    } 

    return (
        <div className="container">
            <div className="row">
                <div className="col">{topic}</div>
                <div className="col">{createdAt}</div>
                <div className="col">{data}</div>
                <div className="col">
                <button type="button" className="btn btn-success" onClick={ handleClick }>{ on ? 'Off' : 'On'}</button>
                </div>
            </div>
        </div>
        // Simple Widget
    );
}

export default OnOffWidget;
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import AuthService, { AuthPayload } from '../services/AuthService';
  
export interface AuthState {
  loading: boolean;
  error: any;
  logged: boolean;
}

const initialState: AuthState = {
  loading: false,
  error: null,
  logged: true
};

export const loginAsync = createAsyncThunk(
  'auth/login',
  async (data: AuthPayload) => {
    const response = await AuthService.login(data);
    return response;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    notlogged: (state) => {
      state.logged = false;
      localStorage.removeItem('token');
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
        state.logged = false;
        state.error = null;
        console.log('>>> loginAsync pending');
        localStorage.removeItem('token');
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.logged = true;
        state.error = null;
        const { access_token } = action.payload;
        if ( access_token) {
            localStorage.setItem('token', access_token);
        }
        console.log('>>> loginAsync fulfilled action: ', action);
      })
      .addCase(loginAsync.rejected, (state, error) => {
        state.loading = false;
        state.logged = false;
        state.error = error.error;
        console.log('>>> loginAsync rejected error: ', error);
        localStorage.removeItem('token');
      });
  },
});

export const { notlogged } = authSlice.actions;
export default authSlice.reducer;

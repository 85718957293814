import HttpService from './HttpService'


export interface GetDataParams {
  limit?: string
  order?: string
  topic?: string
}

export interface SendDataParams {
  topic: string
  data: string
}


class DataService extends HttpService {
  // http://localhost:3000/data?limit=1&order=createdAt:desc&topic=nam/home/aqua/light
  get(params: GetDataParams) {
    return this.http.get('/data', { params })
      .then(this.onResponse)
      .catch(this.onError);
  }

  send(params: SendDataParams) {
    console.log('>>> DataService send ', params);
    return this.http.post('./data', params)
      .then(this.onResponse)
      .catch(this.onError)
  }

}

export default new DataService()

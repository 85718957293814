import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppThunk } from '../app/store'
import AuthService, { AuthPayload } from '../services/AuthService'
import DataService, { SendDataParams } from '../services/DataService'
import * as _ from 'lodash'
import { notlogged } from './AuthSlice'
import UserService from '../services/UserService'

export interface SensorState {
  topic: string
  createdAt?: Date
  data: string
}

export interface DashboardState {
  loading: boolean
  error: any
  sensors: Array<SensorState>
}

const initialState: DashboardState = {
  loading: false,
  error: null,
  sensors: [
    { topic: 'nam/home/aqua/t', data: '' },
    { topic: 'nam/home/aqua/light', data: '' },
    { topic: 'nam/home/aqua/feeder', data: '' },
  ],
}

export const getDataAsync = createAsyncThunk(
  'dashboard/load',
  async (_, { dispatch }) => {
    try {
      // multiple call seems failed on refresh token
      // fetch users first
      await UserService.get()
      const gets = initialState.sensors.map(({ topic }) => {
        return DataService.get({ limit: '1', order: 'createdAt:desc', topic })
      })
      const response = await Promise.all(gets)
      return response
    } catch (e) {
      const { statusCode } = <any>e
      if (statusCode === 401) {
        dispatch(notlogged())
      }
    }
  }
)

export const sendDataAsync = createAsyncThunk(
  'dashboard/send',
  async (data: SendDataParams, { dispatch }) => {
    try {
      return DataService.send(data)
    } catch (e) {
      const { statusCode } = <any>e
      if (statusCode === 401) {
        dispatch(notlogged())
      }
    }
  }
)

export const dashboardSlice = createSlice({
  name: 'dash',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDataAsync.pending, (state) => {
        state.loading = true
        console.log('>>> loadAsync pending')
      })
      .addCase(getDataAsync.fulfilled, (state, action) => {
        state.loading = false
        const s = _.flatten(action.payload)
        state.sensors = s
        console.log('>>> loadAsync fulfilled action: ', action)
      })
      .addCase(getDataAsync.rejected, (state, error) => {
        state.loading = false
        console.log('>>> loadAsync rejected error: ', error)
      })
  },
})

export const {} = dashboardSlice.actions
export default dashboardSlice.reducer

import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

// export default axios.create({
//   baseURL: "https://sh3.lazynam.pp.ua/api",
//   headers: {
//     "Content-type": "application/json"
//   }
// });

export default class HttpService {
  http: AxiosInstance;
  constructor() {
    this.http = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-type': 'application/json',
      },
    })

    this.http.interceptors.request.use(
      config => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.http.interceptors.response.use(
      (request) => {
        // console.log('>>> request: ', request);
        return request;
      }
      , async (error: AxiosError) => {
        // console.log('>>> response error: ', error);
        const originalRequest = error.config || (<any>{});
        const { statusCode } = <any>error.response?.data;
        if (statusCode === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          console.log('DO retry requst here...')
          const { access_token } = await <any>this.http.get('/auth/refresh', { validateStatus: null}).then(this.onResponse);
          if (!access_token) {
            return Promise.reject(error)
          }
          localStorage.setItem('token', access_token);
          originalRequest.headers.Authorization = `Bearer ${ access_token }`;
          console.log('>>> second attempt with access_token: ', access_token);
          return this.http(originalRequest);
        }
        // if (error.response?.data.statusCode)
        return Promise.reject(error);
      }
    );
  }

  onResponse(resp: AxiosResponse): any {
    return resp.data
  }

  onError(e: AxiosError): any {
    const { data } = <any>e.response;
    console.log('>>> http error data: ', data);
    return Promise.reject(data)
  }
}

/**
 import axios from 'axios';
import { has } from 'lodash/fp';

export default class HttpService {
  constructor() {
    this.http = axios.create({ baseURL: process.env.REACT_APP_API_URL, headers: {} });
    this.http.interceptors.request.use(
      config => {
        const token = localStorage.getItem('token') || '';
        config.headers['Authorization'] = `Bearer ${token || ''}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  onResponse({ data }) {
    if (data.error) {
      if (data.error_type) {
        // seems each new php dev provide own error pattern..
        // if we have error_type field, the error field contains error message
        if (data.error_type === 'validation') {
          return Promise.reject({ response: { status: 403 }, message: data.message});
        }
      }
      return Promise.reject({ response: { status: 400 }, message: data.message});
    }
    // Thanks to denis, now the API hasn't always the 'result' key -_-
    return has('result', data) ? data.result : data;
  }

  onError(error) {
    console.error(error);
    let code = 'network_error';
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          code = 'login_error';
          break;
        case 403:
          code = 'validation_error';
          break;
        case 500:
          code = 'server_error';
          break;
        default:
          code = 'unknown_error';
          break;
      }
    }
    return Promise.reject({ ...error, code });
  }
}
* 
 * 
 * 
 * 
 */
